// Получаем ссылки на DOM-элементы интерфейса
const signInBtn = document.getElementById("auth-btn");
const authCard = document.getElementById("auth-card");
/**
 * Основное приложение (HTMl Element)
 * @type {Element}
 */
const app = document.getElementById("app");
const signOutBtn = document.getElementById("sign-out");
const authSpinner = document.querySelector(".spinner-container");
const usernameOutput = document.getElementById("user-name");
const userInfo = document.getElementById("user-info");
const userOperations = document.getElementById("user-operations");
/**
 * Логика для перехода на главную (везде работает)
 * @type {Element}
 */
const goToMainMenuBtn = document.getElementById("go-to-main-menu"); // Элемент кнопки перехода в главное меню
window.goToMainMenuBtn = goToMainMenuBtn;
// Импорт необходимых функций и объектов для работы с авторизацией через Firebase
import { auth, provider } from "./firebase.js";
import {
  signInWithRedirect,
  getRedirectResult,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

/**
 * Функция для авторизации пользователя через Firebase.
 */
async function signInUser() {
  try {
    // Авторизация пользователя
    await signInWithRedirect(auth, provider);

    // Получаем данные пользователя после редиректа
    await getRedirectResult(auth);
  } catch (error) {
    // Обработка возможных ошибок при авторизации
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.email;
    const credential = error.credential;
  }
}

/**
 * Функция для показа интерфейса авторизации
 */
function showAuthInterface() {
  signInBtn.classList.remove("hidden");
  authCard.classList.remove("hidden");
  signOutBtn.classList.add("hidden"); // Убираем кнопку для выхода из аккаунта
}

/**
 * Функция для скрытия интерфейса авторизации
 */
function hideAuthInterface() {
  signInBtn.classList.add("hidden");
  authCard.classList.add("hidden");
  signOutBtn.classList.remove("hidden"); // Делаем возможность выйти из аккаунта (кнопка)
}

// При загрузке документа проверяем статус авторизации пользователя

document.addEventListener("DOMContentLoaded", () => {
  onAuthStateChanged(auth, async (user) => {
    // При проверке статуса авторизации скрываем индикатор загрузки
    authSpinner.classList.add("hidden");
    authCard.classList.remove("hidden");
    loadBookingFromPath(); // Загружаем бронирование из URL
    if (user) {
      // Если вход запрещен для определенного адреса электронной почты
      if (config.forbiddenEmails.some((email) => user.email === email)) {
        alert(`Нельзя входить с ${user.email}, войдите с личной почты`);
        showAuthInterface();
        await signOut(auth);
        return;
      }
      // Записываем данные пользователя
      window.user = user;
      // Если пользователь авторизован, переключаем интерфейс
      hideAuthInterface();
      await toggleMainMenu("show"); // Скрываем все кроме главного меню
      userInfo.classList.remove("hidden");
      userOperations.classList.remove("hidden");
      // temp code for tests
      /*          if (user.email === 'konstantinova.nim@gmail.com'
                || user.email === 'nodenwwsfww@gmail.com'
                || user.email === 'romanradchenko.work@gmail.com') {
                const addAdditionalServiceBtn = document.getElementById('add-refund-payment'); // Элемент кнопки добавления доп.услуги
                addAdditionalServiceBtn.classList.remove('hidden');
            }*/
    } else {
      // Если пользователь не авторизован, скрываем некоторые элементы
      userInfo.classList.add("hidden");
      userOperations.classList.add("hidden");
      window.user = null;
    }
    // Отображаем имя пользователя или очищаем соответствующее поле
    usernameOutput.textContent = user ? user.displayName : "";
  });
});
// Обработчики событий для кнопок входа и выхода
signInBtn.addEventListener("click", async () => {
  await signInUser();
});

signOutBtn.addEventListener("click", async () => {
  try {
    await signOut(auth);
    // Скрываем весь контент кроме авторизации
    app.classList.add("hidden");
    showAuthInterface();
  } catch (error) {
    console.error("Sign out error", error);
  }
});

/**
 Обработчик события "click" для кнопки перехода в главное меню.
 */
goToMainMenuBtn.addEventListener("click", async () => {
  await toggleMainMenu("show"); // Скрываем все элементы, кроме главного меню
});

// function testLogin() {
//   // Set user information
//   const user = {
//     displayName: "[TEST]Maksim Chekhov",
//     email: "chedevnull@gmail.com",
//     uid: "1234567890",
//   };

//   window.user = user;

//   // Update UI to reflect logged-in state
//   usernameOutput.textContent = user.displayName;
//   userInfo.classList.remove("hidden");
//   userOperations.classList.remove("hidden");
//   app.classList.remove("hidden");
//   authSpinner.classList.add("hidden");
//   authCard.classList.add("hidden");

//   // Hide the authentication interface
//   hideAuthInterface();
// }
// testLogin();
